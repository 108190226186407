<template>
    <div name="fade">
        <!--  --> 
      <div class="selectedItemsDev" :style="fixedStyle" >
        <div class="" v-if="itemLength">
          <div class="InnerDiv" >
            <div class="selected-count"
              > <span class="item-count">
                {{ itemLength }} 
              </span> 
              Selected
              </div
            >
            <div class="btn-group" role="group" aria-label="First group">
              
              <button v-if="isDuplicateButton" class="duplicateProducts mx-2" @click.once="$emit('duplicate')">
                <!--  -->
                <span v-if="!isLoadingDuplicate">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 16H6.5C5.39543 16 4.5 15.1046 4.5 14V6C4.5 4.89543 5.39543 4 6.5 4H14.5C15.6046 4 16.5 4.89543 16.5 6V8M10.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V10C20.5 8.89543 19.6046 8 18.5 8H10.5C9.39543 8 8.5 8.89543 8.5 10V18C8.5 19.1046 9.39543 20 10.5 20Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

Duplicate
                </span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
              <button @click="open()"  class="deleteProduct">
                <!--  -->
                <span v-if="!isLoading">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5 7.64706C18.5 7.23285 18.1642 6.89706 17.75 6.89706C17.3358 6.89706 17 7.23285 17 7.64706H18.5ZM8 7.64706C8 7.23285 7.66421 6.89706 7.25 6.89706C6.83579 6.89706 6.5 7.23285 6.5 7.64706H8ZM5.5 6.89706C5.08579 6.89706 4.75 7.23285 4.75 7.64706C4.75 8.06127 5.08579 8.39706 5.5 8.39706V6.89706ZM19.5 8.39706C19.9142 8.39706 20.25 8.06127 20.25 7.64706C20.25 7.23285 19.9142 6.89706 19.5 6.89706V8.39706ZM14.375 7.64706C14.375 8.06127 14.7108 8.39706 15.125 8.39706C15.5392 8.39706 15.875 8.06127 15.875 7.64706H14.375ZM9.125 7.64706C9.125 8.06127 9.46079 8.39706 9.875 8.39706C10.2892 8.39706 10.625 8.06127 10.625 7.64706H9.125ZM17 7.64706V18.2353H18.5V7.64706H17ZM16 19.25H9V20.75H16V19.25ZM8 18.2353V7.64706H6.5V18.2353H8ZM9 19.25C8.45362 19.25 8 18.8016 8 18.2353H6.5C6.5 19.6182 7.61338 20.75 9 20.75V19.25ZM17 18.2353C17 18.8016 16.5464 19.25 16 19.25V20.75C17.3866 20.75 18.5 19.6182 18.5 18.2353H17ZM5.5 8.39706H19.5V6.89706H5.5V8.39706ZM15.875 7.64706V6.76471H14.375V7.64706H15.875ZM13.375 4.25H11.625V5.75H13.375V4.25ZM9.125 6.76471V7.64706H10.625V6.76471H9.125ZM11.625 4.25C10.2384 4.25 9.125 5.3818 9.125 6.76471H10.625C10.625 6.19837 11.0786 5.75 11.625 5.75V4.25ZM15.875 6.76471C15.875 5.3818 14.7616 4.25 13.375 4.25V5.75C13.9214 5.75 14.375 6.19837 14.375 6.76471H15.875Z" fill="currentColor"/>
</svg>
 Delete
                </span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

            </div>
          </div>
        </div>
        <!-- Delete Modal -->
       
        
      </div>
      <DeleteModal id="deleteBulkModal" @click="$emit('click')" ref="deleteModal" :title="`Delete ${name}`" :item="name" :isLoading="isLoading"></DeleteModal>
    </div>
</template>
<script>
import DeleteModal from '../modal/DeleteModal.vue';
export default {
  components:{
    DeleteModal
  },
    props:{
        name:{
            type:String,
            default:'items'
        },
        itemLength:Number,
        isLoading:Boolean,
        isLoadingDuplicate:Boolean,
        isDuplicateButton:Boolean
    },
    data() {
    return {
      atBottom: false,
    };
  },
  computed: {
    fixedStyle() {
      return {
  
        bottom: this.atBottom ? '85px' : '0px',
       
      };
    },
  },
    mounted(){
      window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Check position on initial load
    },
    beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {

   async open(){
      this.$refs.deleteModal.open()
    },
    close(){
      this.$refs.deleteModal.close()
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      this.atBottom = scrollTop + clientHeight >= scrollHeight;
    },
  },
}
</script>
<style scoped>
.selected-count{
  display: flex;
    gap: 8px;
    align-items: center;
    color: #000;
align-items: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.item-count{
  width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-radius: 8px;
    text-align: center;
    background-color: var(--secondary-color);
    color: white;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.selectedItemsDev {
  position: fixed;
    top: auto;
    bottom: 0px;
    z-index: 120;
    left: 43%;
}
.duplicateProducts {
  padding: 7px 12px;
display: flex;
gap: 4px;
border-radius: 6px;
background:var(--white);
border: 1px solid var(--border-color);
color: var(--grey-button);;
font-size: 13px;
font-style: normal;
font-weight: 600;
transition: 0.3s ease all
}
.duplicateProducts:hover{
  background: var(--grey-button);;
  color:var(--white);
}
.deleteProduct {
background:var(--white);
padding: 7px 12px;
display: flex;
gap: 4px;
border-radius: 6px;
border: 1px solid #F85669;
color:#F85669;
font-size: 13px;
font-style: normal;
font-weight: 600;
transition: 0.3s ease all
}
.deleteProduct:hover{
  background: #F85669;
  color:var(--white);
}
.selectedItemsDev .InnerDiv {
  z-index: 999 !important;
  display: inline-flex;
padding: 12px 24px;
gap: var(--gap);
width:437px;
background:var(--white);
border-radius: var(--border-radius);
border: 1px solid var(--border-color);
justify-content: space-between;
box-shadow: 0px 0px 8px 0px rgba(31, 31, 40, 0.14);

}
@media (max-width: 1024px) {
    .selectedItemsDev {
       left:20%;
    }
}
@media (max-width: 557px) {
    .selectedItemsDev {
      left:10%;
    }
}
</style>