<template>
  <div class="d-flex justify-content-center justify-content-md-between align-items-center">
    <div class="d-flex align-items-center gap" v-if="isShowPerPageSelect">
      <div class="per-page-select d-none d-md-block">
        <select name="" v-model.number="perPageData" id="per-page-select">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="entries-info">
        Showing {{ startEntry }} to {{ endEntry }} of {{ totalRows }} entries
      </div>
    </div>
    <div class="pagination">
      <button
        :disabled="currentPage == 1"
        @click="goToPage(currentPage - 1)"
        class="page-button side-buttons"
      >
       <i class="fa fa-chevron-left pr-2" aria-hidden="true"></i> Previous
      </button>
  <div class="d-flex align-items-center">
      <button
        v-for="page in firstThreePages"
        :key="page"
        :style="{pointerEvents: page == currentPage?'none':'auto'}"
        :class="{ active: page == currentPage }"
        @click="goToPage(page)"
        class="page-button"
      >
        {{ page }}
      </button>
  
      <span v-if="showFirstEllipsis" class="ellipsis">...</span>
  <span v-for="page in middlePages" :key="'page-button-' + page" >
    
      <button
      :style="{pointerEvents: page == currentPage?'none':'auto'}"
        v-if="(page>3&&page<(totalPages-2))||page==4||page==5"
        :class="{ active: page == currentPage }"
        @click="goToPage(page)"
        class="page-button"
      >
        {{ page }}
      </button>
      <!-- <button
        v-show="page==3"
        :class="{ active: page == 4 }"
        @click="goToPage(4)"
        class="page-button"
      >
       4
      </button> -->
    </span>
      <span v-if="showLastEllipsis" class="ellipsis">...</span>
  
      <button
      :style="{pointerEvents: page == currentPage?'none':'auto'}"
        v-for="page in lastThreePages"
        :key="page"
        :class="{ active: page == currentPage }"
        @click="goToPage(page)"
        class="page-button"
      >
        {{ page }}
      </button>
    </div>
      <button
        :disabled="currentPage == totalPages"
        @click="goToPage(currentPage + 1)"
        class="page-button side-buttons"
      >
      Next
     <i class="fa fa-chevron-right pl-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data(){
      return {
        perPageData:this.perPage
      }
    },
    watch:{
      perPageData(newValue){
        this.$emit('UpdatePerPage',newValue)
      }
    },
    props: {
      currentPage: {
        type: [Number],
        required: true
      },
      totalRows: {
        type: [Number, String, Array],
        required: true
      },
      perPage: {
        type: [Number, String, Array],
        required: true
      },
      isShowPerPageSelect:{
        type: Boolean,
        default:true,
      },
    },
    computed: {
      startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalRows);
    },
      totalPages() {
        return Math.ceil(this.totalRows / this.perPage);
      },
      firstThreePages() {
        let pages = [];
        let endPage = Math.min(3, this.totalPages);
        for (let i = 1; i <= endPage; i++) {
          pages.push(i);
        }
        return pages;
      },
      middlePages() {
  if (this.totalPages <= 3) {
    // If total pages are less than or equal to 3, or the current page is 1, 2, or 3,
    // display the first three pages.
    return this.firstThreePages;
  } else {
    let pages = [];
    let startPage = Math.max(this.currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, this.totalPages);
    if (endPage - startPage < 2) {
      startPage = Math.max(endPage - 2, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }
},


      lastThreePages() {
        if(!this.showFirstEllipsis) return
        let pages = [];
        let startPage = Math.max(this.totalPages - 2, 1);
        for (let i = startPage; i <= this.totalPages; i++) {
          pages.push(i);
        }
        return pages;
      },
      showFirstEllipsis() {
        return this.currentPage > 3 && this.totalPages > 6;
      },
      showLastEllipsis() {
        return this.currentPage < this.totalPages - 3 && this.totalPages > 6;
      }
    },
    methods: {
      goToPage(page) {
        if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
          this.$emit('update', page);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .side-buttons{
    border:1px solid var(--border-color);
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;
    
  }
  
  .page-button {
    padding: 0.5rem 0.75rem;
    border: none;
   
    cursor: pointer;
   
    color: #73738D;

text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
background: white;

  }
  
  .page-button:not(:disabled):not(.active):hover {
    border-radius: 8px;
    background: rgba(77, 27, 126, 0.1);
    color: var(--primary-color);
  }
  
  .page-button:disabled {
    /* background-color: rgba(77, 27, 126, 0.1) !important; */
    color: #73738D !important;
    cursor: not-allowed;
    opacity: 0.6;
    border-radius: 8px;
  }
  
  .active {
    border-radius: 8px;
    background: rgba(77, 27, 126, 0.1);
    color: var(--primary-color);
  }
  .entries-info{
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-button);
  }
  
  .ellipsis {
    padding: 0.5rem;
  }
  #per-page-select{
    border:none;
    outline: none;
  }
  .per-page-select{
    border: 1px dashed var(--border-color);
    border-radius: 12px;
    padding: 0px 7px;
    line-height: 0px;
  }
  @media (max-width: 600px) {
    .pagination {
      flex-wrap: wrap;
      gap: 0.25rem;
    }
    .page-button {
      padding: 0.25rem 0.5rem;
    }
    .ellipsis {
      padding: 0.25rem;
    }
  }
  </style>
  